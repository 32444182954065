(function () {
	'use strict';
	angular.module('app').directive('welcomeFrame', ['$sce', welcomeFrame]);

	function welcomeFrame($sce) {
		return {
			restrict: 'EA',
			replace: true,
			scope: {},
			link: function (scope, element, attrs) {
				if (attrs.type === 'sharing') {
					scope.frameURL = $sce.trustAsResourceUrl(
						'https://dayback.com/welcome-sharing'
					);
				} else if (attrs.type === 'google') {
					scope.frameURL = $sce.trustAsResourceUrl(
						'https://dayback.com/welcome-google/'
					);
				} else if (attrs.type === 'basecamp') {
					scope.frameURL = $sce.trustAsResourceUrl(
						'https://dayback.com/welcome-basecamp/'
					);
				} else if (attrs.type === 'office365') {
					scope.frameURL = $sce.trustAsResourceUrl(
						'https://dayback.com/welcome-office365/'
					);
				} else if (attrs.type === 'filemakerclient') {
					scope.frameURL = $sce.trustAsResourceUrl(
						'https://dayback.com/welcome-filemakerclient/'
					);
				} else {
					scope.frameURL = $sce.trustAsResourceUrl(
						'https://dayback.com/welcome/'
					);
				}
			},
			template:
				'<div id="iframe-container" class="col-md-6 col-lg-6" resize-height>' +
				'<iframe class="iframe" ng-src="{{frameURL}}"></iframe>' +
				'</div>',
		};
	}
})();
